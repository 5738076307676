import React from 'react';
import {
  Text,
  withSitecoreContext,
  RichText,
  isExperienceEditorActive,
} from '@sitecore-jss/sitecore-jss-react';
import { Formik } from 'formik';
import { ApolloConsumer, Query } from 'react-apollo';
import { loader as gqlLoader } from 'graphql.macro';
import './ratingReviews.scss';
import StarRating from '../StarRating';
import RatingReviewsFilter from '../RatingReviewsFilter';
import ReactComponent from '../../ReactComponent';
import i18next from 'i18next';
import { DotLoader } from 'react-spinners';
import { handleSubmitReview } from '../Tagging';

const RATINGS_QUERY = gqlLoader('./ratingReviews.graphql');
const CommentsQuery = gqlLoader('./ratingReviewsComments.graphql');

class RatingReviews extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      status: '',
      rating: '',
      clickedStar: '',
      username: '',
      commentValue: '',
      itemdata: null,
      templateId: null,
      nbLoad: null,
      showButton: true,
    };
    this.onStarClick = this.onStarClick.bind(this);
    this.userNameChange = this.userNameChange.bind(this);
    this.commentChange = this.commentChange.bind(this);
    this.rankLines = this.rankLines.bind(this);
    this.updateData = this.updateData.bind(this);
    this.loadMore = this.loadMore.bind(this);
  }

  componentDidMount() {
    this.activeRatingStatusId().then((res) => {
      if (res) {
        this.setState({
          templateId: res.data.search.results.items[0].item.id.toLowerCase(),
          nbLoad: this.props.fields.NbToLoad && parseInt(this.props.fields.NbToLoad.value),
        });
      }
    });
  }

  componentDidUpdate(nextProps) {
    if (nextProps.sitecoreContext.route.name !== this.props.sitecoreContext.route.name) {
      this.setState({ itemdata: null, nbLoad: this.props.fields.NbToLoad });
    }
  }

  onStarClick(event) {
    let ratingValue = event.target.parentElement.getAttribute('data-rating');
    let clickedStarId = event.target.id;

    this.setState({
      rating: ratingValue,
      clickedStar: clickedStarId,
    });
  }

  userNameChange(event) {
    this.setState({ username: event.target.value });
  }

  commentChange(event) {
    this.setState({
      commentValue: event.target.value,
    });
  }

  updateData(data) {
    this.setState({
      itemdata: data,
    });

    if (data) {
      if (data.length < this.state.nbLoad) {
        this.setState({
          showButton: false,
        });
      } else {
        this.setState({
          showButton: true,
        });
      }
    }
  }

  loadMore() {
    if (this.state.nbLoad !== null) {
      this.setState({
        nbLoad: this.state.nbLoad + parseInt(this.props.fields.NbToLoad.value),
      });

      if (
        this.state.nbLoad >=
        this.state.itemdata.length - parseInt(this.props.fields.NbToLoad.value)
      ) {
        this.setState({
          showButton: false,
        });
      }
    }
  }

  rankLines(items) {
    let result = [];
    const data = items;

    // add stars in element
    for (let i = 0; i < 5; i++) {
      const stars = [];
      for (let j = 0; j < i + 1; j++) {
        let star = (
          <svg width="15px" height="13px" viewBox="0 0 15 13" version="1.1">
            <path
              d="M14.2661 4.87843C14.2299 4.77093 14.1228 4.69756 14.0026 4.69756L8.96198 4.69756L7.40348 0.18015C7.36573 0.0733706 7.25865 5.34589e-06 7.14001 5.34589e-06C7.01983 -0.000721043 6.91429 0.0726442 6.87654 0.179423L5.30726 4.69756L0.277411 4.69756C0.157231 4.69756 0.0501474 4.77093 0.0139393 4.87843C-0.0238097 4.98448 0.0170208 5.10434 0.113319 5.17044L4.18481 7.96631L2.61553 12.5091C2.57933 12.6159 2.62016 12.7343 2.71645 12.8019C2.81275 12.868 2.94526 12.868 3.04156 12.8019L7.14001 9.99657L11.2285 12.8019C11.2762 12.8346 11.3332 12.852 11.3925 12.852C11.448 12.852 11.505 12.8346 11.5543 12.8019C11.6506 12.7343 11.6915 12.6166 11.6537 12.5091L10.086 7.96631L14.1652 5.17044C14.263 5.10434 14.3038 4.98521 14.2661 4.87843"
              id="Fill-1-Copy-4"
              fill="#DFCF9D"
              fillRule="evenodd"
              stroke="none"
            />
          </svg>
        );
        stars.push(star);
      }

      // number of comments by rates
      const nbOfComments = data.filter((el) => el.item.rating.numberValue === i + 1).length;

      // percentage for the bar
      const percentage = Math.floor((nbOfComments / data.length) * 100) + '%';

      // pushing lines
      result.push(
        <div key={i} className="line">
          <div className="starsContainer">{stars}</div>
          <div className="rankBarContainer">
            <div className="rankBar" style={{ width: percentage }} />
          </div>
          <span className={'nbReviewsLine'}>
            {nbOfComments} {i18next.t('Reviews')}
          </span>
        </div>
      );
    }

    return result.reverse();
  }

  render() {
    return (
      <React.Fragment>
        <div id="rating-container" className="rating-container RandR">
          <div>
            <div className="separate" />
            <h2 className="title-review">
              <Text field={this.props.fields && this.props.fields.CommentsSectionTitle} />
            </h2>
            <div id="reviews" className="Reviews">
              <div id="comments">
                <Query
                  query={CommentsQuery}
                  variables={{
                    rootPath: '/sitecore/content/' + this.appName + '/Content/Rating and Reviews',
                    indexname: this.indexName,
                    productId: this.props.sitecoreContext.itemId.replace(/-/g, '').toLowerCase(),
                    ratingstatusId: this.state.templateId !== null ? this.state.templateId : '',
                  }}
                >
                  {({ loading, error, data }) => {
                    if (loading)
                      return (
                        <div
                          className="sweet-loading"
                          style={{ marginTop: '50px', marginBottom: '50px' }}
                        >
                          <DotLoader
                            sizeUnit={'px'}
                            size={50}
                            color={'#DFCF9D'}
                            loading={!this.state.isLoaded}
                          />
                        </div>
                      );

                    if (error) return <div>Error: {error.message}</div>;

                    let globalRank = [];
                    let items = data.search.results.items;

                    let dataToload;

                    if (this.state.itemdata === null) {
                      dataToload = data.search.results.items;
                    } else {
                      dataToload = this.state.itemdata;
                    }

                    if (items.length === 0)
                      return isExperienceEditorActive() && <div>{t('NoTimelineFoundLabel')}</div>;
                    else if (items.length !== 0) globalRank = this.globalRank(items);

                    const reducer = (accumulator, currentValue) => accumulator + currentValue;
                    let nbStars =
                      Math.round((globalRank.reduce(reducer) / globalRank.length) * 10) / 10;

                    return (
                      <React.Fragment>
                        <div className="reviewsSummary">
                          <div className="row">
                            <div className="globalScore col-md-3">
                              <div className="rank">
                                <span>{globalRank.length !== 0 ? nbStars : '0'} / 5</span>
                              </div>
                              <StarRating value={nbStars} isLittleSvg={false} />{' '}
                              <span className={'nbReviews'}>
                                {globalRank.length} {i18next.t('Reviews')}{' '}
                              </span>
                            </div>
                            <div className="scoreRanking col-md-5">
                              <div className="linesContainer">{this.rankLines(items)}</div>
                            </div>
                            <div className="giveAdvice col-md-4">
                              <svg width="70px" height="70px" viewBox="0 0 70 70" version="1.1">
                                <defs>
                                  <filter id="filter_1">
                                    <feColorMatrix
                                      in="SourceGraphic"
                                      type="matrix"
                                      values="0 0 0 0 0 0 0 0 0 0.49803922 0 0 0 0 0.75686276 0 0 0 1 0"
                                    />
                                  </filter>
                                  <path
                                    d="M0 0L65.6495 0L65.6495 65.523L0 65.523L0 0Z"
                                    transform="translate(0.00052500004 0.97650003)"
                                    id="path_1"
                                  />
                                </defs>
                                <g id="IconAvis-70px">
                                  <g id="Group-8" transform="translate(1.75 0)">
                                    <g id="Group-3" transform="translate(0 0.773325)">
                                      <path
                                        d="M0 0L65.6495 0L65.6495 65.523L0 65.523L0 0Z"
                                        transform="translate(0.00052500004 0.97650003)"
                                        id="Clip-2"
                                        fill="none"
                                        fillRule="evenodd"
                                        stroke="none"
                                      />
                                      <g clipPath="url(#mask_1)">
                                        <path
                                          d="M63.1995 52.6732C63.1995 52.9585 62.9685 53.1895 62.6833 53.1895L57.8882 53.1895C57.211 53.1895 56.6633 53.7372 56.6633 54.4145L56.6633 61.313L49.007 53.5535C48.7777 53.3207 48.4627 53.1895 48.1355 53.1895L22.4438 53.1895C22.1585 53.1895 21.9258 52.9585 21.9258 52.6732L21.9258 23.4342C21.9258 23.149 22.1585 22.918 22.4438 22.918L62.6833 22.918C62.9685 22.918 63.1995 23.149 63.1995 23.4342L63.1995 52.6732ZM2.982 32.7232C2.688 32.7232 2.45 32.4835 2.45 32.1895L2.45 2.98375C2.45 2.68975 2.688 2.45175 2.982 2.45175L43.19 2.45175C43.484 2.45175 43.7238 2.68975 43.7238 2.98375L43.7238 20.468L22.4438 20.468C20.8058 20.468 19.4758 21.798 19.4758 23.4342L19.4758 32.7232L2.982 32.7232ZM62.6833 20.468L46.1737 20.468L46.1737 2.98375C46.1737 1.33875 44.8368 0 43.19 0L2.982 0C1.337 0 0 1.33875 0 2.98375L0 32.1895C0 33.8345 1.337 35.1732 2.982 35.1732L19.4757 35.1732L19.4757 52.6732C19.4757 54.3095 20.8057 55.6395 22.4437 55.6395L47.6227 55.6395L57.0168 65.1595C57.2512 65.3975 57.5662 65.5235 57.8882 65.5235C58.0457 65.5235 58.2033 65.4938 58.352 65.4325C58.8122 65.2435 59.1132 64.7955 59.1132 64.2985L59.1132 55.6395L62.6833 55.6395C64.3195 55.6395 65.6495 54.3095 65.6495 52.6732L65.6495 23.4342C65.6495 21.798 64.3195 20.468 62.6833 20.468L62.6833 20.468Z"
                                          transform="translate(0.00052500004 0.975975)"
                                          id="Fill-1"
                                          fill="#DFCF9D"
                                          fillRule="evenodd"
                                          stroke="none"
                                        />
                                      </g>
                                    </g>
                                    <path
                                      d="M22.5032 0L1.225 0C0.54775 0 0 0.54775 0 1.225C0 1.90225 0.54775 2.45 1.225 2.45L22.5032 2.45C23.1805 2.45 23.7282 1.90225 23.7282 1.225C23.7282 0.54775 23.1805 0 22.5032 0"
                                      transform="translate(30.776024 43.65865)"
                                      id="Fill-4"
                                      fill="#DFCF9D"
                                      fillRule="evenodd"
                                      stroke="none"
                                    />
                                    <path
                                      d="M22.5032 0L1.225 0C0.54775 0 0 0.54775 0 1.225C0 1.90225 0.54775 2.45 1.225 2.45L22.5032 2.45C23.1805 2.45 23.7282 1.90225 23.7282 1.225C23.7282 0.54775 23.1805 0 22.5032 0"
                                      transform="translate(30.776024 35.047775)"
                                      id="Fill-6"
                                      fill="#DFCF9D"
                                      fillRule="evenodd"
                                      stroke="none"
                                    />
                                  </g>
                                </g>
                              </svg>
                              <p className={'experience'}>{i18next.t('experience')}</p>
                              <a className="btn" href="#review_form_wrapper">
                                {i18next.t('comment')}
                              </a>
                            </div>
                          </div>
                        </div>
                        <RatingReviewsFilter
                          onFilterChange={this.updateData}
                          comments={dataToload}
                        />
                        <ol className="commentlist">
                          {dataToload &&
                            (this.state.nbLoad
                              ? dataToload.slice(0, this.state.nbLoad)
                              : dataToload
                            ).map((commentItem, index) => (
                              <li
                                className="review even thread-even depth-1"
                                key={index}
                                id="li-comment-36"
                              >
                                <div id="comment-36" className="comment_container">
                                  <div className="comment-text">
                                    <StarRating
                                      value={commentItem.item.rating.numberValue}
                                      isLittleSvg={true}
                                    />
                                    <p className="meta">
                                      <span className="review__author">
                                        {commentItem.item.userName.value}{' '}
                                      </span>
                                      <time className="review__published-date">
                                        {commentItem.item.creationDate.value}
                                      </time>
                                    </p>
                                    <div className="description">
                                      <p>{commentItem.item.comment.value}</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ol>
                        {this.state.nbLoad < dataToload.length && (
                          <div className="col-12 text-center pb-5">
                            <button className={'btn load-more'} onClick={() => this.loadMore()}>
                              {i18next.t('load-more')}
                            </button>
                          </div>
                        )}
                      </React.Fragment>
                    );
                  }}
                </Query>
              </div>
              <div id="review_form_wrapper">
                <div id="review_form">
                  <div id="respond" className="comment-respond">
                    <span id="reply-title" className="comment-reply-title same__font">
                      <Text field={this.props.fields.RatingLabel} />
                    </span>
                    {this.state.show === false && this.state.status === 'success' && (
                      <div className="alert alert-success" role="alert">
                        {i18next.t('thank-you')}
                      </div>
                    )}
                    {this.state.show && this.state.show === true && (
                      <ApolloConsumer>
                        {(client) => (
                          <Formik
                            enableReinitialize
                            initialValues={{
                              userName: this.state.username,
                              rating: this.state.rating,
                              comment: this.state.commentValue,
                              product: '',
                              privacy: false,
                            }}
                            onSubmit={async (values, { setErrors, setSubmitting }) => {
                              const { data } = await client.query({
                                query: RATINGS_QUERY,
                                variables: {
                                  userName: values.userName,
                                  rating: values.rating,
                                  comment: values.comment,
                                  product: this.props.sitecoreContext.itemId,
                                },
                              });
                              if (data.ratingreviews === 'Review Submitted Successfully') {
                                handleSubmitReview(
                                  this.props.sitecoreContext.route.displayName,
                                  this.props.sitecoreContext.route.fields.codeEan.value
                                );
                                this.setState({
                                  show: false,
                                  status: 'success',
                                });
                              } else {
                                setErrors({ submit: 'Error sending review.' });
                                setSubmitting(false);
                              }
                            }}
                            validate={(values) => {
                              let errors = {};
                              if (!values.privacy) {
                                errors.privacy = t('required');
                              }
                              if (!values.comment) {
                                errors.comment = t('required');
                              }

                              return errors;
                            }}
                          >
                            {(props) => {
                              const { values, touched, errors, handleChange, handleSubmit } = props;

                              return (
                                <React.Fragment>
                                  <div>
                                    <form
                                      id="commentform"
                                      className="comment-form another__font"
                                      onSubmit={handleSubmit}
                                    >
                                      <p className="comment-notes">
                                        <Text field={this.props.fields.RequiredFieldsInfo} />
                                      </p>
                                      <div className="form-control-wrap">
                                        <input
                                          type="text"
                                          name="userName"
                                          id="userName"
                                          size="40"
                                          className="form-control"
                                          placeholder={i18next.t('fullName')}
                                          value={this.state.username}
                                          onChange={this.userNameChange}
                                        />
                                      </div>
                                      <div className="comment-form-rating same__font">
                                        <label htmlFor="rating">{i18next.t('your-score')}</label>
                                        <p className="stars another__font">
                                          <span>
                                            <a
                                              className="star-1 same__font"
                                              data-rating="1"
                                              onClick={this.onStarClick}
                                            >
                                              <i
                                                className={
                                                  'rating-1' === this.state.clickedStar ||
                                                  1 <= this.state.rating
                                                    ? 'fas fa-star'
                                                    : 'far fa-star off'
                                                }
                                                id="rating-1"
                                              />
                                            </a>
                                            <a
                                              className="star-2 same__font"
                                              data-rating="2"
                                              onClick={this.onStarClick}
                                            >
                                              <i
                                                className={
                                                  'rating-2' === this.state.clickedStar ||
                                                  2 <= this.state.rating
                                                    ? 'fas fa-star'
                                                    : 'far fa-star off'
                                                }
                                                id="rating-2"
                                              />
                                            </a>
                                            <a
                                              className="star-3 same__font"
                                              data-rating="3"
                                              onClick={this.onStarClick}
                                            >
                                              <i
                                                className={
                                                  'rating-3' === this.state.clickedStar ||
                                                  3 <= this.state.rating
                                                    ? 'fas fa-star'
                                                    : 'far fa-star off'
                                                }
                                                id="rating-3"
                                              />
                                            </a>
                                            <a
                                              className="star-4 same__font"
                                              data-rating="4"
                                              onClick={this.onStarClick}
                                            >
                                              <i
                                                className={
                                                  'rating-4' === this.state.clickedStar ||
                                                  4 <= this.state.rating
                                                    ? 'fas fa-star'
                                                    : 'far fa-star off'
                                                }
                                                id="rating-4"
                                              />
                                            </a>
                                            <a
                                              className="star-5 same__font"
                                              data-rating="5"
                                              onClick={this.onStarClick}
                                            >
                                              <i
                                                className={
                                                  'rating-5' === this.state.clickedStar
                                                    ? 'fas fa-star'
                                                    : 'far fa-star off'
                                                }
                                                id="rating-5"
                                              />
                                            </a>
                                          </span>
                                        </p>
                                      </div>
                                      <p className="comment-form-comment another__font">
                                        <textarea
                                          id="comment"
                                          name="comment"
                                          cols="45"
                                          rows="8"
                                          placeholder={i18next.t('commentRate')}
                                          required=""
                                          value={this.state.commentValue}
                                          onChange={this.commentChange}
                                        />
                                        {errors.comment && touched.comment && (
                                          <div className="alert alert-danger" role="alert">
                                            {errors.comment}
                                          </div>
                                        )}
                                      </p>
                                      <div className="pprivacy same__font">
                                        <input
                                          type="checkbox"
                                          id="privacy"
                                          name="privacy"
                                          value="privacy-key"
                                          checked={values.privacy === true}
                                          className="privacyBox"
                                          onChange={handleChange}
                                        />
                                        <RichText field={this.props.fields.Privacy} />
                                        {errors.privacy && touched.privacy && (
                                          <div className="alert alert-danger" role="alert">
                                            {errors.privacy}
                                          </div>
                                        )}
                                      </div>
                                      <p className="form-submit another__font">
                                        <input
                                          name="submit"
                                          type="submit"
                                          id="submit"
                                          className="load-more"
                                          value={i18next.t('submitComment')}
                                        />
                                        <input
                                          type="hidden"
                                          name="comment_post_ID"
                                          value="983"
                                          id="comment_post_ID"
                                        />
                                        <input
                                          type="hidden"
                                          name="comment_parent"
                                          id="comment_parent"
                                          value="0"
                                        />
                                      </p>
                                    </form>
                                  </div>
                                </React.Fragment>
                              );
                            }}
                          </Formik>
                        )}
                      </ApolloConsumer>
                    )}
                  </div>
                  <div className="row_custom_small Legal-form">
                    <RichText field={this.props.fields.LegaInformation} />
                  </div>
                </div>
              </div>
              <div className="clear" />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSitecoreContext()(RatingReviews);
